import axios from "@/core/services/axios";
import _ from "lodash";
import router from "@/router";

const SET_ACCOUNT = "SET_ACCOUNT";
const SET_PRIMARY_ROLE = "SET_PRIMARY_ROLE";

const state = {
  account: {},
};

const mutations = {
  [SET_PRIMARY_ROLE](state, role) {
    state.account = {
      ...state.account,
      data: { ...state.account.data, primaryRole: role },
    };
  },
  [SET_ACCOUNT](state, info) {
    state.account = info;
  },
};

const actions = {
  getAccount({ commit, rootGetters }) {
    const platform = rootGetters["platform/platformId"];
    return axios.get(`/alien/${platform}/accounts/me`).then((response) => {
      if (response.data) {
        commit(SET_ACCOUNT, response.data);
        const role = rootGetters["user/getCurrentRole"];
        if (
          role === "investee" &&
          _.get(response, "data.investee.state") !== "active"
        ) {
          router.push("/investee/account");
        }
        if (role === "investor") {
          router.push("/investor");
        }
      }
      return response.data;
    });
  },
  createNewAccount({ commit, rootGetters }) {
    const user = rootGetters["user/currentUser"];
    const platform = rootGetters["platform/platformId"];
    const primaryRole = rootGetters["user/getCurrentRole"];

    const payload = {
      data: {
        primaryRole,
        lead: {
          email: user.email,
          givenName: user.firstName,
          familyName: user.lastName,
          telephone: "",
        },
        company: {
          displayName: user.organisation || "",
          website: "",
          baseCountry: "",
        },
        investee: {
          hasRole: primaryRole === "investee",
          state: primaryRole === "investee" ? "new" : "",
        },
        investor: {
          hasRole: primaryRole === "investor",
          state: primaryRole === "investor" ? "new" : "",
        },
      },
    };

    return axios
      .post(`/alien/${platform}/accounts`, payload)
      .then((response) => {
        if (response.data) {
          commit(SET_ACCOUNT, response.data);
        }
        return response.data;
      });
  },
  updateAccount({ state, commit, rootGetters }, data) {
    const account = { ...state.account, data };
    const platform = rootGetters["platform/platformId"];
    if (_.get(account, "data.investee.state") === "new") {
      _.set(account, "data.investee.state", "review");
    }
    return axios
      .patch(`/alien/${platform}/accounts/me`, account)
      .then((response) => {
        commit(SET_ACCOUNT, response.data);
      });
  },
  ensureAccount({ dispatch }) {
    return dispatch("getAccount").catch(() => {
      return dispatch("createNewAccount");
    });
  },
  setPrimaryRole({ commit }, role) {
    commit(SET_PRIMARY_ROLE, role);
  },
};

const getters = {
  account: (state) => state.account,
  primaryRole: (state) =>
    _.get(state.account, "data.primaryRole") || "investor",
  accountState: (state, getters, rootState, rootGetters) => {
    const role = rootGetters["user/getCurrentRole"];
    return _.get(state, `account.data.${role}.state`, "new");
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import VueGtag from "vue-gtag";
import ApiService from "@/core/services/ApiService";
import AppConfig from "@/config/app.config";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { createMetaManager } from "vue-meta";
import fetchedConfig from "../build/fetched-config.json";

// Vueform
import Vueform from "@vueform/vueform";
import vueformConfig from "../vueform.config";

import "@/core/plugins/prismjs";
const app = createApp(App);

store.dispatch("platform/setPlatform", fetchedConfig);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(createMetaManager());
app.use(Vueform, vueformConfig);

if (AppConfig.trackingGA) {
  app.use(
    VueGtag,
    {
      config: { id: AppConfig.trackingGA },
    },
    router
  );
}

const faviconLink = document.querySelector(
  "link[rel~='icon']"
) as HTMLAnchorElement;

if (faviconLink) {
  const favicon = store.getters["platform/config"]?.favicon;
  faviconLink.href = favicon || "/favicon.ico";
}

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

import en from "@vueform/vueform/locales/en";
import bootstrap from "@vueform/vueform/themes/bootstrap";
import FilesUpload from "vdr-framework/src/custom-components/files-management-sharepoint/vueform/files-upload.vue";

export default {
  theme: bootstrap,
  locales: { en },
  locale: "en",
  elements: [FilesUpload],
};

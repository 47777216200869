const production = {
  apiUrl: process.env.VUE_APP_API_URL || "https://awf-api.azurewebsites.net",
  recaptchaApikey: "6Ld-BgwTAAAAAGLLsBHy_7WdQA9BHAi6R1ZEA4r-",
  recaptchaValidResponse:
    "03AHJ_VuulQ3Vhnk24UfCfPtOigxVqHWsNjYJI_kn3cSmHC87et17zUa0xhOHyYlLQ3AeN0II2RH5LexR5yztGatPI0LUrg_JaBn4cOOGmxGc31La8aLBHVmD80XVLMA0lNZ-IwzQ8CJaiC6cdAwYzWbNiqDZ4eYy4HDgZlEe1SKnOIA7pC3qXWckTqnXc5XS--Nd5KuVXuUF6F2wROKcScu7qrH66Ci4ih9i3WoYuF7H9_Q2rLUcfUYNtJG-rs9hQBWLK1TXWNMnnVizSfvcgk7jR3lXLbFwRAMdgHt6WcvgLGmz5b5QNaZRb6q6_WoqMzeeoF4ez-BLlu5VmXsGnqut2CRBT8blDaO-vIL5tw8pAqjQ-_yhZC2uf0IUq1dLrnT23X8uONxXdW2rfo4R8Ei7OADxvDbbsYRMCkflEyGzmiBla-Dy5pOD17ShgS2pl5BFjupkorOLNt0CL1kKoyI0l3uu-NkQ5ryzs8jotIPYUySFyeSyByAlJ61Tz4JwWHxWDtiP-2wbsnCHI1IPvgQ8eYO3whHBqmi_FYJwXc1gj0S3B2SUn5gETwBH4GYLt20qKCWX5W7p8OilTmyioTzEPY-mZtrGYFyun8TntpqJtMtDUhEzQ8O5rhEf5oFrL66_xbx11fgXiUhQFjUJ-sRZRGXCUjgSejZKBfbQxBzlVuQcdJKYlr87_fs99yFCmgKZ0qVyMo66l9EraofELvNveXVI66rsF_1661UU5ohxb7GRH7v3k9k_hqL7ynJa8F8NePSuFcpwLOcQQy3Bzi5WQ-c5ExnEAyhAgtCFyqKwcU8pxbf0WkUYdYJ8MePg8hrmGptPG6ZcrQd-oQbGxoH-B_U62kD0txxcPACitrDHM4Jou0kWBeNkWs46V6GsTnoJ_x29X947yJ7vTBFnQFsNE-dUFtQv_Ww",
  releaseStage: "production",
  trackingGA: "",
};

const staging = {
  apiUrl: process.env.VUE_APP_API_URL || "https://awf-api.azurewebsites.net",
  recaptchaApikey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  recaptchaValidResponse:
    "03AHJ_VuulQ3Vhnk24UfCfPtOigxVqHWsNjYJI_kn3cSmHC87et17zUa0xhOHyYlLQ3AeN0II2RH5LexR5yztGatPI0LUrg_JaBn4cOOGmxGc31La8aLBHVmD80XVLMA0lNZ-IwzQ8CJaiC6cdAwYzWbNiqDZ4eYy4HDgZlEe1SKnOIA7pC3qXWckTqnXc5XS--Nd5KuVXuUF6F2wROKcScu7qrH66Ci4ih9i3WoYuF7H9_Q2rLUcfUYNtJG-rs9hQBWLK1TXWNMnnVizSfvcgk7jR3lXLbFwRAMdgHt6WcvgLGmz5b5QNaZRb6q6_WoqMzeeoF4ez-BLlu5VmXsGnqut2CRBT8blDaO-vIL5tw8pAqjQ-_yhZC2uf0IUq1dLrnT23X8uONxXdW2rfo4R8Ei7OADxvDbbsYRMCkflEyGzmiBla-Dy5pOD17ShgS2pl5BFjupkorOLNt0CL1kKoyI0l3uu-NkQ5ryzs8jotIPYUySFyeSyByAlJ61Tz4JwWHxWDtiP-2wbsnCHI1IPvgQ8eYO3whHBqmi_FYJwXc1gj0S3B2SUn5gETwBH4GYLt20qKCWX5W7p8OilTmyioTzEPY-mZtrGYFyun8TntpqJtMtDUhEzQ8O5rhEf5oFrL66_xbx11fgXiUhQFjUJ-sRZRGXCUjgSejZKBfbQxBzlVuQcdJKYlr87_fs99yFCmgKZ0qVyMo66l9EraofELvNveXVI66rsF_1661UU5ohxb7GRH7v3k9k_hqL7ynJa8F8NePSuFcpwLOcQQy3Bzi5WQ-c5ExnEAyhAgtCFyqKwcU8pxbf0WkUYdYJ8MePg8hrmGptPG6ZcrQd-oQbGxoH-B_U62kD0txxcPACitrDHM4Jou0kWBeNkWs46V6GsTnoJ_x29X947yJ7vTBFnQFsNE-dUFtQv_Ww",
  releaseStage: "staging",
  trackingGA: "G-VH8XFQ4J7Z",
};

const development = {
  apiUrl: "http://localhost:7011",
  recaptchaApikey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  recaptchaValidResponse:
    "03AHJ_VuulQ3Vhnk24UfCfPtOigxVqHWsNjYJI_kn3cSmHC87et17zUa0xhOHyYlLQ3AeN0II2RH5LexR5yztGatPI0LUrg_JaBn4cOOGmxGc31La8aLBHVmD80XVLMA0lNZ-IwzQ8CJaiC6cdAwYzWbNiqDZ4eYy4HDgZlEe1SKnOIA7pC3qXWckTqnXc5XS--Nd5KuVXuUF6F2wROKcScu7qrH66Ci4ih9i3WoYuF7H9_Q2rLUcfUYNtJG-rs9hQBWLK1TXWNMnnVizSfvcgk7jR3lXLbFwRAMdgHt6WcvgLGmz5b5QNaZRb6q6_WoqMzeeoF4ez-BLlu5VmXsGnqut2CRBT8blDaO-vIL5tw8pAqjQ-_yhZC2uf0IUq1dLrnT23X8uONxXdW2rfo4R8Ei7OADxvDbbsYRMCkflEyGzmiBla-Dy5pOD17ShgS2pl5BFjupkorOLNt0CL1kKoyI0l3uu-NkQ5ryzs8jotIPYUySFyeSyByAlJ61Tz4JwWHxWDtiP-2wbsnCHI1IPvgQ8eYO3whHBqmi_FYJwXc1gj0S3B2SUn5gETwBH4GYLt20qKCWX5W7p8OilTmyioTzEPY-mZtrGYFyun8TntpqJtMtDUhEzQ8O5rhEf5oFrL66_xbx11fgXiUhQFjUJ-sRZRGXCUjgSejZKBfbQxBzlVuQcdJKYlr87_fs99yFCmgKZ0qVyMo66l9EraofELvNveXVI66rsF_1661UU5ohxb7GRH7v3k9k_hqL7ynJa8F8NePSuFcpwLOcQQy3Bzi5WQ-c5ExnEAyhAgtCFyqKwcU8pxbf0WkUYdYJ8MePg8hrmGptPG6ZcrQd-oQbGxoH-B_U62kD0txxcPACitrDHM4Jou0kWBeNkWs46V6GsTnoJ_x29X947yJ7vTBFnQFsNE-dUFtQv_Ww",
  releaseStage: "development",
  trackingGA: "G-VH8XFQ4J7Z",
};

const configs = {
  development,
  production,
  staging,
};

const getConfig = () => {
  const env = process.env.VUE_APP_ENV || "development";
  return configs[env];
};

module.exports = getConfig();

import SHA1 from "crypto-js/sha1";
import _ from "lodash";
import axios from "@/core/services/axios";
import router from "@/router";
import { checkTokenExpiration } from "asokoinsight-tokens/src/helpers";
import { SET_CURRENT_USER, CLEAR_USER } from "./mutation-types";
import { userTypeMap } from "@/constants";

const state = {
  currentUser: {},
};

const mutations = {
  [SET_CURRENT_USER](state, user) {
    state.currentUser = user;
  },
  [CLEAR_USER](state) {
    state.currentUser = {};
  },
};

const sessionHandler = ({ commit, dispatch }, data) => {
  const displayName = data.displayName || data.username;
  const publicRoles = data.roles;
  const email = data.primaryEmail;
  let firstName = _.get(data, "data.firstName");
  let lastName = _.get(data, "data.lastName");
  const [givenName, familyName] = (displayName || "").split(" ");
  if (!firstName) {
    firstName = givenName || "<not specified>";
  }
  if (!lastName) {
    lastName = familyName || "<not specified>";
  }

  const user = {
    name: displayName,
    firstName,
    lastName,
    email,
    accessToken: data.token.accessToken,
    intercomHmac: data.data.intercomHmac,
    userId: data.id,
    isActiveSubscriber: false,
    roles: publicRoles,
    interestId: data.data.interestId,
  };

  commit(SET_CURRENT_USER, user);
  dispatch("tokens/setTokensData", data.token, { root: true });
  return dispatch("account/ensureAccount", null, { root: true });
};

const actions = {
  checkAndRefresh({ dispatch }) {
    const refreshExpiresAt = localStorage.getItem("refreshExpiresAt");
    let isExpired = false;
    if (refreshExpiresAt) {
      isExpired = checkTokenExpiration(refreshExpiresAt);
    }
    if (refreshExpiresAt && !isExpired) {
      dispatch("tokens/refreshAccessToken", null, { root: true }).catch(
        (err) => {
          console.log(err);
          dispatch("tokens/setTokenChecked", null, { root: true });
        }
      );
    } else {
      dispatch("tokens/setTokenChecked", null, { root: true });
    }
  },
  login({ commit, dispatch }, payload) {
    return axios
      .post("/users/sessions?v2", {
        login: payload.email,
        password: payload.password,
      })
      .then((res) => {
        sessionHandler({ commit, dispatch }, res.data);
      });
  },
  handleSessionUpdate({ commit, dispatch }, data) {
    sessionHandler({ commit, dispatch }, data);
  },
  handleRefreshFail({ commit }) {
    commit(CLEAR_USER);
    router.push("/");
  },
  logout({ commit, dispatch }) {
    return axios
      .delete("/users/sessions/me")
      .then(() => {
        commit(CLEAR_USER);
        dispatch("tokens/clearTokens", null, { root: true });
      })
      .catch((err) => {
        if (_.get(err, "response.status") === 401) {
          location.reload();
        }
      });
  },
  register({ commit, dispatch, getters }, userData) {
    const newAccount = { ...userData };

    const emailHash = SHA1(userData.email).toString();
    newAccount.username = `${userData.firstName}_${emailHash}`;

    newAccount.displayName = `${newAccount.firstName}  ${newAccount.lastName}`;

    newAccount.acceptTerms = !!newAccount.acceptTerms;

    delete newAccount.password_confirmation;

    newAccount.userType = userTypeMap[getters["getCurrentRole"]];

    //start HARDCODE
    newAccount.sector = "Advisory - Management Consulting";
    //end HARDCODE

    const analyticsUtm = _.pickBy(
      userData,
      (val, key) => _.startsWith(key, "utm") && val
    );
    if (!_.isEmpty(analyticsUtm)) {
      newAccount.analyticsUtm = analyticsUtm;
    }

    return axios.post("/users/new?v2", newAccount).then((res) => {
      const user = res.data.user;
      const displayName = user.displayName || user.username;
      const publicRoles = user.roles;
      let firstName = _.get(user, "data.firstName");
      let lastName = _.get(user, "data.lastName");
      const [givenName, familyName] = (displayName || "").split(" ");
      if (!firstName) {
        firstName = givenName || "n/a";
      }
      if (!lastName) {
        lastName = familyName || "n/a";
      }
      const currentUser = {
        name: displayName,
        firstName,
        lastName,
        email: user.primaryEmail,
        accessToken: res.data.token.accessToken,
        userId: user._id,
        isActiveSubscriber: false,
        roles: publicRoles,
        organisation: _.get(user, "data.organisation", ""),
      };

      commit(SET_CURRENT_USER, currentUser);
      dispatch("tokens/setTokensData", res.data.token, { root: true });
      return dispatch("account/ensureAccount", null, { root: true });
    });
  },
  clearCurrentUser({ commit, dispatch }) {
    commit(CLEAR_USER);
    dispatch("tokens/clearTokens", null, { root: true });
  },
  resetPassword({ getters }, payload) {
    payload.path = `${window.location.origin}/#/${getters["getCurrentRole"]}/auth/password-recovery`;
    return axios.post("/users/reset-password", { ...payload, platform: "awf" });
  },
};

const getters = {
  currentUser: (state) => state.currentUser,
  isLoggedIn: (state) => !_.isEmpty(state.currentUser),
  userToken: (state) => state.currentUser.accessToken,
  userId: (state) => state.currentUser.userId || "guest",
  userSubmissionId: (state) => state.currentUser.interestId,
  getCurrentRole: () => {
    const currentPath = router.currentRoute.value.fullPath;
    const subStrings = currentPath.split("/");
    return subStrings[1];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

interface Values {
  awfStrategicPillars: string | boolean;
  awfSectors: string | boolean;
  isPrioritizedByGovernment: boolean;
  isProgressiveFocus: boolean;
  isProjectCoFinanced: boolean;
  isProjectCommitted: boolean;
}

export default function isQualified(values: Values) {
  return (
    values.awfStrategicPillars &&
    values.awfSectors &&
    values.isPrioritizedByGovernment &&
    values.isProgressiveFocus &&
    values.isProjectCoFinanced &&
    values.isProjectCommitted
  );
}

import axios from "@/core/services/axios";
import router from "@/router";
import _ from "lodash";

const SET_CURRENT_STEP_INTERMEDIARY = "SET_CURRENT_STEP_INTERMEDIARY";
const SET_SUBMISSION_STATUS_INTERMEDIARY = "SET_SUBMISSION_STATUS_INTERMEDIARY";
const SET_SUBMISSION_INFO_INTERMEDIARY = "SET_SUBMISSION_INFO_INTERMEDIARY";
const SET_INVESTEES_LIST = "SET_INVESTEES_LIST";
const SET_CURRENT_INVESTEE_DETAIL = "SET_CURRENT_INVESTEE_DETAIL";

const state = {
  step: 1,
  status: {
    step: 1,
    view: "",
  },
  submission: {},
  listOfInvestees: {},
  investeeDetail: {},
};

const mutations = {
  [SET_CURRENT_STEP_INTERMEDIARY](state, payload) {
    state.step = payload;
  },
  [SET_SUBMISSION_STATUS_INTERMEDIARY](state, payload) {
    state.status = payload;
  },
  [SET_SUBMISSION_INFO_INTERMEDIARY](state, payload) {
    state.submission = payload;
  },
  [SET_INVESTEES_LIST](state, payload) {
    state.listOfInvestees = payload;
  },
  [SET_CURRENT_INVESTEE_DETAIL](state, payload) {
    state.investeeDetail = payload;
  },
};

const actions = {
  createNewSubmission({ commit, dispatch, rootGetters }) {
    const user = rootGetters["user/currentUser"];
    const data = {
      status: {
        step: 1,
        view: "application",
      },
      submission: {
        interest: {},
        company: {},
        lead: {
          email: user.email,
          givenName: user.firstName,
          familyName: user.lastName,
        },
      },
    };
    return axios
      .post("/alien/submissions?entity=intermediary", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO_INTERMEDIARY, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INTERMEDIARY, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  saveSubmission({ commit, dispatch }, { submission, status }) {
    var data = {
      submission,
      status,
    };
    const currentSubmission = state.submission;
    delete currentSubmission._id;
    return axios
      .post("/alien/submissions?entity=intermediary", {
        ...currentSubmission,
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO_INTERMEDIARY, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INTERMEDIARY, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setCurrentStep({ commit }, payload) {
    commit(SET_CURRENT_STEP_INTERMEDIARY, payload);
    const steps = {
      1: "intermediary-application",
      2: "intermediary-review",
      3: "intermediary-onboarding",
      4: "intermediary-deal-support",
    };
    router.push({ name: steps[payload] });
  },
  setCurrentUserSubmission({ commit, dispatch, rootGetters }) {
    const userId = rootGetters["user/userId"];
    return axios
      .get("/alien/submissions?entity=intermediary")
      .then((response) => {
        const userSubmissions = response.data.items.filter(
          (item) => item.userId === userId
        );
        const userSubmission = userSubmissions[0];
        if (userSubmission) {
          const status = userSubmission.data.status;
          commit(SET_SUBMISSION_STATUS_INTERMEDIARY, status);

          const submission = userSubmission;
          commit(SET_SUBMISSION_INFO_INTERMEDIARY, submission);

          dispatch("setCurrentStep", status.step);
        }
        return userSubmission;
      });
  },
  setNewStatus({ dispatch, state, commit }, payload) {
    var data = {
      submission: state.submission,
      status: payload,
    };
    return axios
      .post("/alien/submissions?entity=intermediary", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INTERMEDIARY, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setListOfInvestees({ commit, state }, payload) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get("/alien/advisors", {
        params: { intermediarySubmissionId: submissionId, type: payload.type },
      })
      .then((response) => {
        commit(SET_INVESTEES_LIST, response.data.items);
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    return axios.patch("/alien/advisors/" + payload._id, payload).then(() => {
      const type = _.get(router, "currentRoute.value.params.listType");
      dispatch("setListOfInvestees", { type });
    });
  },
  getInvesteeDetail({ commit }, payload) {
    return axios
      .get(`/alien/submissions/${payload}?entity=investee`)
      .then((response) => {
        commit(SET_CURRENT_INVESTEE_DETAIL, response.data.items[0]);
      });
  },
};

const getters = {
  currentStep: (state) => state.step,
  submission: (state) => state.submission?.data?.submission,
  isStepFinished: (state) => (num) => state.status.step > num,
  view: (state) => state.status.view,
  status: (state) => state.status,
  getListOfInvestees: (state) => state.listOfInvestees,
  getCurrentInvesteeData: (state) => state.investeeDetail,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
